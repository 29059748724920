import { graphql, Link, useStaticQuery } from 'gatsby';
import * as React from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import HeroSection from '../components/Blocks/HeroSection/heroSection';
import Paragraph from '../components/Blocks/Paragraph/paragraph';
import Section from '../components/Blocks/Section/section';
import Layout from '../components/layout';
import SEO from '../components/SEO/seo';

const SelfHelpPodcastPage = () => {

  const data = useStaticQuery(graphql`
    {
        allFeedSelfhelp {
          nodes {
            enclosure {
              length
              type
              url
            }
            itunes {
              episode
            }
            content
            contentSnippet
            title

          }
        }
      }
  `);

  const episodePageUrl = (episodeNumber: string) => {
    switch (episodeNumber) {
      case '1':
        return '/selfhelp/designingyourlife';
      default:
        return '/selfhelp';
    }
  };

  return (
    <Layout backto="/">
        <SEO title={'Cелфхелп'} description="Подкаст о self-help книгах, которые должны помогать людям бороться с проблемами самостоятельно и становиться лучше. Таких книг стало очень и много и разобраться в них очень сложно. Этот подкаст создан для того чтобы помочь вам найти книгу которая поможет лично вам." keywords={['селфхелп', 'artyom', 'alekseev', 'Artyom Alekseev', 'подкаст', 'артём', 'алексеев', 'книги для развития', 'карнеги', 'искусство пофигизма']} />
        <HeroSection animationType="noanima" heading="Подкаст селфхелп" desc="Подкаст о self-help книгах, которые должны помогать людям бороться с проблемами самостоятельно и становиться лучше. Этот подкаст создан для того чтобы помочь вам найти книгу которая поможет лично вам."/>
        {data.allFeedSelfhelp.nodes.map(val =>
        <Section linkTo={episodePageUrl(val.itunes.episode)} heading={val.title}>
            <Paragraph>{val.contentSnippet}</Paragraph>
            <AudioPlayer src={val.enclosure.url}></AudioPlayer>
        </Section>,
        )}
    </Layout>
  ); };

export default SelfHelpPodcastPage;
